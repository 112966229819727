import ModalComponent from '@components/Modal/ModalComponent.vue';

import { Vue, axios, isset } from '@master';

const flashMessages = new Vue({
    el: '#flash_messages',
    data() {
        return {
            emailVerified: {
                sending: false,
                modalOpen: true
            },
            flashMessage: {
                modalOpen: false
            },
            moondance: {
                modalOpen: true
            }
        }
    },
    methods: {
        resendValidation(userId) {
            this.emailVerified.sending = true;
            axios.post(`/manage/user/${userId}/resendValidation`).then(response => {
                this.swalSuccess("Verification email has been sent!");
                this.emailVerified.modalOpen = false;
                this.emailVerified.sending = false;
            }).catch(error => {
                this.swalError(error.data.message);
                this.emailVerified.modalOpen = false;
                this.emailVerified.sending = false;
            });
        },
        moondanceModalConfirm() {
            this.moondance.modalOpen = false;
            localStorage.setItem('moondanceSeenModal', 'true');
        }
    },
    mounted() {
        if(isset(this.$refs.flash_message)) {
            this.flashMessage.modalOpen = true;
        }

        if (localStorage.getItem('moondanceSeenModal') === 'true') {
            this.moondance.modalOpen = false;
        }
    },
    components: {
        'modal': ModalComponent
    }
});
